/* eslint-disable */
export default (safe) => {
    return {
      translate: true,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: true,
      addBtn: true,
      editBtn: true,
      tip: false,
      index: true,
      align: 'center',
      headerAlign: 'center',
      border: false,
      stripe: false,
      defaultExpandAll:false,
      searchMenuSpan: 4,
      card: false,
      maxHeight: '550px',
      refreshBtn: false,
      columnBtn: false,
      searchShowBtn: false,
      column: [
        {
          label: "角色名称",
          prop: "name",
          overHidden:true,
          search: true,
          rules: [{
            required: true,
            message: "请输入",
            // trigger: "blur"
          }]
        },
        // {
        //   label: "是否系统默认",
        //   prop: "asDefault",
        //   addDisplay: false,
        //   editDisplay: false,
        //   formatter: (row, value, label, column) => {
        //     let dic = {
        //       0: '否',
        //       1: '是',
        //     }
        //     return dic[value] || '未知'
        //   }
        // },
        // {
        //   label: "能否进入后台",
        //   prop: "asAdmin",
        //   type: 'select',
        //   value: 1,
        //   addDisplay: false,
        //   editDisplay: false,
        //   dicData: [
        //     {
        //       label: '是',
        //       value: 1,
        //     },
        //     {
        //       label: '否',
        //       value: 0,
        //     }
        //   ]
        // },
        {
          label: "备注",
          prop: "remark",
          overHidden:true,
          type: 'textarea',
        },
        {
          label: "功能",
          prop: "btn",
          addDisplay: false,
          editDisplay: false,
          slot:true,
          width: '300px'
        }
      ],
    }
  }