// import {guid} from "@/utils/index"
import {formatTime} from "@/utils/index"
export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: true,
      addBtn: false,
      editBtn: false,
      menu: false,
      tip: false,
      index: true,
      align: 'center',
      refreshBtn: false,
      columnBtn: false,
      searchShowBtn: false,
      headerAlign: 'center',
      border: false,
      stripe: false,
      defaultExpandAll:false,
      searchMenuSpan: 4,
      card: false,
      maxHeight: '550px',
      column: [
        {
          label: "图片id",
          prop: "picId",
          showColumn: false,
        },
        {
          label: "用户名",
          prop: "loginName",
          search: true,
          rules: [{
            required: true,
            message: "请输入",
            // trigger: "blur"
          }]
        },
        {
            label: "查看IP",
            prop: "viewIp",
        },
        {
          label: "查看时间",
          prop: "viewTime",
          formatter: (row, value, label, column) => {
            return formatTime(parseInt(value), 'yyyy-MM-dd HH:mm:ss')
          }
        },
      ],
    }
  }