
import {ajax as $ajax} from "@/utils/libs/utils.http.js";


export const list = (params)=> {
    return  $ajax({
        url: '/user/user-core/page',
        type: "get",
        dataType: "formData",
        data: params
    })
}
export const add = (params)=> {
    return  $ajax({
        url: '/user/user-core/resister',
        type: "POST",
        dataType: "formData",
        data: params
    })
}
export const del = ()=> {

}
export const update = ()=> {

}

// 【查询】请求头像的授权sts
export const getStsAvatar = (data) => {
    return $ajax({
        url: '/v1/oss/sts/avatar',
        method: 'get',
        params: data
    })
}