
import {ajax as $ajax} from "@/utils/libs/utils.http.js";


export const list = (params)=> {
    return  $ajax({
        url: '/v1/auth/role/page',
        type: "get",
        dataType: "formData",
        data: params
    })
}
export const add = (params)=> {
    return  $ajax({
        url: '/v1/auth/role',
        type: "post",
        dataType: "json",
        data: params
    })
}
export const del = (roleId)=> {
    return  $ajax({
        url: '/v1/auth/role/' + roleId,
        type: "delete",
        dataType: "json",
    })
}
export const update = (id, params)=> {
    return  $ajax({
        url: '/v1/auth/role',
        type: "put",
        dataType: "json",
        data: params
    })
}

export const cloneRolefunc = (params) => {
    return $ajax({
        url: '/v1/auth/role-func/clone/' + params.fromRoleId + '/' + params.toRoleId,
        type: "post",
        dataType: "json",
        data: params
    })
}