export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: false,
      addBtn: false,
      delBtn: false,
      editBtn: false,
      tip: false,
      index: true,
      align: 'center',
      headerAlign: 'center',
      border: true,
      stripe: true,
      defaultExpandAll:true,
      rowKey: 'menuId',
      searchMenuSpan: 8,
      searchLabelWidth: 120,
      column: [
        {
        label: "菜单名称",
        prop: "name",
      },{
        label: "节点代号",
        prop: "nodeCode",
      },{
        label: "父节点代码",
        prop: "parentNodeCode",
      },{
        label: "图标",
        prop: "icon",
      },{
        label: "级别",
        prop: "level",
      },{
        label: "路由",
        prop: "routes",
      },{
        label: "权重",
        prop: "weight",
      }
    ],
    }
  }