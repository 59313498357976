import { default as $config }  from "@/config"
import { Message } from 'element-ui'

import $router from "../../router/index";

// http 请求
function ajax(params){
            
    let {url, data = {}, hasLoading = true, dataType = 'json'} = params;
    // autoHandle = true
    let type = params.type == "get" ? "GET" : "POST";

    if(params.type == "put") type = "PUT";
    if(params.type == "delete") type = "DELETE";
    let token = sessionStorage.getItem('uetk');
    // 实际地址
    url = $config.host + url;
    let options = {};
    if(dataType == "json"){
        options = {
            method: type,
            headers :{ 
                "Content-Type": "application/json;charset=UTF-8",
                "Accept" : "application/json",
                "uetk" : token
            }
        }
    }else{
        options = {
            method: type,
            headers :{ "uetk" : token }
        }
    }
    
    
    if(params.filePath){
        let formData = new FormData();

        for (var key in data){
            formData.append(key, data[key]);
        }
        
        let file = params.filePath;
        formData.append("file", file, file.name);
        // 请求头更改
        delete options.headers["Content-Type"];
        options.body = formData;
    }else{
        if(type == "GET"){
            let bodyData = [];
            Object.keys(data).forEach(key => {
                bodyData.push( key + "=" + encodeURIComponent(data[key]));
            });
            url +="?" + bodyData.join("&");     
        }else{
            if(dataType == "json"){
                options.body = JSON.stringify(data);
            }else{
                let formData = new FormData();
                for (const key in data) {
                    formData.append(key,data[key]);
                }
                options.body = formData;
            }
        }
    }
    
    return new Promise((resolve,reject) => {
        fetch(url,options)
        .then((res)=>{
            return res.json();
        }).then(res => {
            res.msg     = res.errMsg;
            res.code    = res.resCode == "0x200" ? 200 : parseInt((res.resCode).replace('_', '')); 
            res.data    = res.result;
            
            delete res.resCode;
            delete res.result;

            // 关闭Loadding
            if(hasLoading){
                // $ui.removeWait();
            }
            // 错误自动处理
            if(res.code != 200){
                Message({
                    message: res.msg || 'error',
                    type: 'error',
                    duration: 5 * 1000
                })
                if (res.code == "10020") {
                    sessionStorage.removeItem('uetk');
                    $router.replace("/login");
                }
                reject(res);
            }
            resolve(res);

        })
        .catch(error => {
            reject(error);
        });
    });    
}

export {ajax};
