import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      auth: true
    },
    // component: () => import('../views/index.vue')
    component: () => import('../views/img.vue')
  },
  // {
  //     path: "/index",
  //     name: "index",
  //     meta: {
  //         auth : true,
  //         title: "首页",
  //         keep : false,
  //         keepAlive: true,
  //     },
  //     component: () => import('../views/index.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/role',
    name: 'role',
    meta: {
      auth : true,
      title: "角色管理",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/role.vue')
  },
  {
    path: '/img',
    name: 'img',
    meta: {
      auth : true,
      title: "图片管理",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/img.vue')
  },
  {
    path: '/instion',
    name: 'instion',
    meta: {
      auth : true,
      title: "机构管理",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/instion.vue')
  },
  {
    path: '/doctor',
    name: 'doctor',
    meta: {
      auth : true,
      title: "医生管理",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/doctor.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      auth : true,
      title: "权限",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/auth.vue')
  },
  {
    path: '/log',
    name: 'log',
    meta: {
      auth : true,
      title: "权限",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/log.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      auth : true,
      title: "角色管理",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/user.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: {
      auth : true,
      title: "统计",
      keep : false,
      keepAlive: true,
    },
    component: () => import('../views/statistics.vue')
  },
]
const router = new VueRouter({
  routes
})

// 全局路由守卫
router.beforeEach((to,from,next)=>{
  // console.log('全局路由守卫', to);
  if(to.meta.auth){ 
      let token = sessionStorage.getItem('uetk');
      // const { title } = to.meta;
      // document.title  = title;
      // // 每次更新uinfo因为有可能账号角色权限变更 或者是否专家变更
      // token && store.commit("updateUinfo");
      // next();
      if(token){
          next();
      }else{
          next("/login");
      }
  }else{
      next();
  }
});
export default router