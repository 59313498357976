
import {ajax as $ajax} from "@/utils/libs/utils.http.js";


export const list = (params)=> {
    return  $ajax({
        url: '/v1/manager/pic/page',
        type: "get",
        dataType: "formData",
        data: params
    })
}
export const add = (params)=> {
}
export const del = (params)=> {
    return  $ajax({
        url: '/v1/manager/pic/' + params.picId,
        type: "delete",
        dataType: "formData",
        data: params
    })
}
export const update = ()=> {

}

export const checkimg = (picId) => {
    return  $ajax({
        url: '/v1/manager/pic/detail/' + picId,
        type: "get",
        dataType: "formData",
    })
}
export const sendRecordimg = (picId) => {
    return  $ajax({
        url: '/v1/manager/pic/download/' + picId,
        type: "post",
        dataType: "json",
    })
}