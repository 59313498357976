

import {ajax as $ajax} from "@/utils/libs/utils.http.js";
// table默认格式 增删改查 start=====

export const del = ()=> {
  return  $ajax({
      url: '/v1/auth/role-range',
      type: "delete",
      dataType: "json",
  })
}
export const list = (params)=> {
  return  $ajax({
      url: '/v1/auth/role-range/page',
      type: "get",
      dataType: "formData",
      data: params
  })
}
export const add = (params)=> {
  return  $ajax({
      url: '/v1/auth/role-range',
      type: "post",
      dataType: "json",
      data: params
  })
}
// table默认格式 start=====

