import {ajax as $ajax} from "@/utils/libs/utils.http.js";
// table默认格式 增删改查 start=====
export const list = (params)=> {
  return  $ajax({
      url: '/v1/auth/user-role/page',
      type: "get",
      dataType: "formData",
      data: params
  })
}
export const add = (params)=> {
  return  $ajax({
      url: '/v1/auth/user-role',
      type: "post",
      dataType: "json",
      data: params
  })
}
export const del = (params)=> {
  return  $ajax({
      url: '/v1/auth/user-role/' + params.userId + '/' + params.roleId,
      type: "delete",
      dataType: "json",
  })
}
// export const update = (id, params)=> {
//   return  $ajax({
//       url: '/v1/auth/role',
//       type: "put",
//       dataType: "json",
//       data: params
//   })
// }

// table默认格式 start=====

