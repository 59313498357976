export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: true,
      addBtn: false,
      delBtn: false,
      editBtn: false,
      tip: false,
      index: true,
      align: 'center',
      headerAlign: 'center',
      border: true,
      stripe: true,
      defaultExpandAll:false,
      searchMenuSpan: 8,
      searchLabelWidth: 120,
      menu:true,
      column: [
        {
        label: "绑定角色名称",
        prop: "targetRoleName",
      },{
        label: "绑定的角色id",
        prop: "targetRoleId",
        hide: true,
        addDisplay: false,
        editDisplay: false,
      },{
        label: "是否绑定",
        prop: "asBind",
        addDisplay: false,
        editDisplay: false,
        formatter: (row, value, label, column) => {
          let dic = {
            0: '否',
            1: '是',
          }
          return dic[value] || '未知'
        }
      }
    ],
    }
  }