let host = "https://ai-api-sc.chengdumaixun.com";
if(process.env.NODE_ENV == "development"){
    // host = "http://192.168.10.195:31089";
    host = "https://ai-api-sc.chengdumaixun.com";
}

const base = host;

export default {
    base,
    host : `${host}`,
}



