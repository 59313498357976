import {ajax as $ajax} from "@/utils/libs/utils.http.js";
// table默认格式 增删改查 start=====

export const cloneRoleMenu = (params)=> {
  return  $ajax({
      url: '/v1/auth/role-menu-tree/clone/' + params.fromRoleId + '/' + params.toRoleId,
      type: "post",
      dataType: "json",
      data: params
  })
}

export const list = (params)=> {
  return  $ajax({
    url: '/v1/auth/role-menu-tree/page',
      type: "get",
      dataType: "formData",
      data: params
  })
}
export const add = (params)=> {
  return  $ajax({
      url: '/v1/auth/role-menu-tree',
      type: "post",
      dataType: "json",
      data: params
  })
}
export const del = ()=> {
  return  $ajax({
      url: '/v1/auth/role-menu-tree',
      type: "delete",
      dataType: "json",
  })
}
// export const update = (id, params)=> {
//   return  $ajax({
//       url: '/v1/auth/role',
//       type: "put",
//       dataType: "json",
//       data: params
//   })
// }


// table默认格式 start=====

