
import {ajax as $ajax} from "@/utils/libs/utils.http.js";


export const list = (params)=> {
    return  $ajax({
        url: '/v1/manager/doctor/page',
        type: "get",
        dataType: "formData",
        data: params
    })
}
export const add = (params)=> {
 
}
export const del = ()=> {

}
export const update = ()=> {

}
