// import { getStsAvatar } from "@/api/user/index"
import {formatTime} from "@/utils/index"
export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: true,
      addBtn: false,
      delBtn: false,
      editBtn: false,
      menu: false,
      tip: false,
      index: true,
      refreshBtn: false,
      columnBtn: false,
      searchShowBtn: false,
      align: 'center',
      headerAlign: 'center',
      border: false,
      stripe: false,
      defaultExpandAll:false,
      searchMenuSpan: 4,
      card: false,
      maxHeight: '600px',
      column: [
        {
          label: "医院名称",
          prop: "hosName",
          search: true,
        },
        {
          label: "省",
          prop: "province",
        },
        {
          label: "市",
          prop: "city",
        },
        {
          label: "区",
          prop: "district",
        },
        // {
        //   label: "图片的接收时间",
        //   prop: "receiveTime",
        //   formatter: (row, value, label, column) => {
        //     console.log('', value);
        //     return formatTime(parseInt(value), 'yyyy-MM-dd HH:mm:ss')
        //   }
        // },
      ],
    }
  }