// import { getStsAvatar } from "@/api/user/index"
import {formatTime} from "@/utils/index"
export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: true,
      addBtn: false,
      editBtn: false,
      menu: false,
      tip: false,
      index: true,
      align: 'center',
      headerAlign: 'center',
      border: false,
      stripe: false,
      defaultExpandAll:false,
      searchMenuSpan: 4,
      card: false,
      maxHeight: '550px',
      refreshBtn: false,
      columnBtn: false,
      searchShowBtn: false,
      column: [
        {
            label: "备份时间",
            prop: "createdTime",
            formatter: (row, value, label, column) => {
              return formatTime(parseInt(value), 'yyyy-MM-dd HH:mm:ss')
            }
        },
        {
            label: "备份状态",
            prop: "status",
            type: 'select',
            dicData: [
              {
                label: '失败',
                value: 0,
              },
              {
                label: '成功',
                value: 1,
              },
            ],
        },
        {
          label: "备份日志",
          prop: "remark",
        }
      ],
    }
  }