export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: false,
      addBtn: false,
      delBtn: false,
      editBtn: false,
      tip: false,
      index: true,
      align: 'center',
      headerAlign: 'center',
      border: true,
      stripe: true,
      defaultExpandAll:false,
      searchMenuSpan: 8,
      searchLabelWidth: 120,
      column: [
        {
          label: "角色名称",
          prop: "name",
          search: true,
          clearable: false,
        },
        {
            label: "是否可以登录后台",
            prop: "asAdmin",
            type: 'select',
            dicData: [
                {
                    label: '否',
                    value: 0
                },
                {
                    label: '是',
                    value: 1
                },
            ],
            formatter: (row, value, label, column) => {
                let dic = {
                    0: '否',
                    1: '是',
                }
                return dic[value] || '未知'
            }
        },
        {
            label: "是否是系统内置",
            prop: "asDefault",
            type: 'select',
            dicData: [
                {
                    label: '否',
                    value: 0
                },
                {
                    label: '是',
                    value: 1
                },
            ],
            formatter: (row, value, label, column) => {
                let dic = {
                    0: '否',
                    1: '是',
                }
                return dic[value] || '未知'
            }
        },
        {
          label: "备注",
          prop: "remark",
          type: 'textarea',
        },
        {
          label: "是否具有该功能",
          prop: "asBind",
          addDisplay: false,
          editDisplay: false,
          type: 'select',
          dicData: [
            {
                label: '否',
                value: 0
            },
            {
                label: '是',
                value: 1
            },
          ],
          formatter: (row, value, label, column) => {
            let dic = {
              0: '否',
              1: '是',
            }
            return dic[value] || '未知'
          }
        },
      ],
    }
  }