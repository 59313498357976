<template>
    <el-dialog 
        :width ="width" 
        :title ="title" 
        :visible.sync ="isShow"
        :modal="false"
        :destroy-on-close="false"
        :close-on-click-modal = "isMaskClose"
        custom-class  = "ui-model"
    >
        <slot></slot>
    </el-dialog>
</template>

<script>
export default{
    props : {
        title : {
            type : String,
            default : ""
        },

        width : {
            type : String,
            default : "900px"
        },

        isMaskClose : {
            type : Boolean,
            default : false
        }
    },

    data(){
        return {
            isShow : false
        }
    },

    methods : {
        show(){
            this.isShow = true;
        },

        hide(){
            this.isShow = false;
        }
    }
};
</script>

<style>
    .ui-model {
        z-index: 20021 !important;
    }
    .ui-model .el-dialog__body{ padding:10px 20px !important; }
</style>

