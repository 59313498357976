// import { getStsAvatar } from "@/api/user/index"
// import {guid} from "@/utils/index"
export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: true,
      addBtn: true,
      editBtn: false,
      menu: false,
      tip: false,
      index: true,
      align: 'center',
      headerAlign: 'center',
      border: false,
      stripe: false,
      defaultExpandAll:false,
      searchMenuSpan: 4,
      refreshBtn: false,
      columnBtn: false,
      searchShowBtn: false,
      card: false,
      maxHeight: '550px',
      column: [
        {
          label: "登录名",
          prop: "loginName",
          search: true,
          rules: [{
            required: true,
            message: "请输入",
            // trigger: "blur"
          }]
        },
        {
            label: "密码",
            prop: "passwd",
            type: 'password',
            autocomplete: "off",
            hide: true,
            showColumn: false,
        },
        {
            label: "真实名称",
            prop: "realName",
        },
        {
          label: "电话号码",
          prop: "telNum",
        },
        {
          label: "医院名称",
          prop: "hospital",
        },
        // {
        //     label: "头像地址",
        //     prop: "avatar",
        //     type: 'upload',
        //     addDisplay: true,
        //     editDisplay: false,
        //     listType: 'picture-img',
        //     showColumn: false,
        //     // display: false,
        //     accept:'image/png, image/jpeg',
        //     oss: "ali",
        // },
        {
            label: "备注",
            prop: "remark",
            type: 'textarea',
            addDisplay: true,
            editDisplay: false,
        },
        {
          label: "功能",
          prop: "btn",
          addDisplay: false,
          editDisplay: false,
          slot:true,
        }
      ],
    }
  }