// import { getStsAvatar } from "@/api/user/index"
import {formatTime} from "@/utils/index"
export default (safe) => {
    return {
      translate: false,
      searchLabelWidth: 100,
      dialogDirection: 'rtl',
      dialogType: 'drawer',
      excelBtn: false,
      labelWidth: 110,
      selection: true,
      addBtn: false,
      delBtn: false,
      editBtn: false,
      refreshBtn: false,
      columnBtn: false,
      searchShowBtn: false,
      menu: true,
      tip: false,
      index: true,
      align: 'center',
      headerAlign: 'center',
      border: false,
      stripe: false,
      defaultExpandAll:false,
      searchMenuSpan: 4,
      card: false,
      maxHeight: '590px',
      column: [
        {
          label: "图片名称",
          prop: "picName",
        },
        {
          label: "医生名称",
          search: true,
          prop: "docName",
        },
        {
          label: "医院名称",
          search: true,
          prop: "hosName",
        },
        {
          label: "切面类型",
          prop: "autoType",
        },
        {
          label: "图片类型",
          prop: "picType",
          search: true,
          searchValue: [1,3],
          type: 'select',
          multiple: true,
          dicData: [
            {
              label: '未知',
              value: 0
            },
            {
              label: '单图',
              value: 1
            },
            {
              label: '父图',
              value: 2
            },
            {
              label: '子图',
              value: 3
            }
          ],
          formatter: (row, value, label, column) => {
            let dic = {
              0: '未知',
              1: '单图',
              2: '父图',
              3: '子图'
            }
            return dic[value]
          }
        },
        {
          label: "切面标准情况",
          prop: "autoStdType",
        },
        {
          label: "切面整体得分",
          prop: "overallScore",
        },
        {
          label: "上传时间",
          prop: "receiveTime",
          formatter: (row, value, label, column) => {
            return formatTime(parseInt(value), 'yyyy-MM-dd HH:mm:ss')
          }
        },
      ],
    }
  }